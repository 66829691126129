<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <div v-for="game in games" :key="`game_${game.id}`">
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>{{ game.title }}</label>
                  <md-input v-model="game.comission" type="number" />
                  <span class="md-suffix">%</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button class="md-raised md-success"> Создать </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      games: [
        {
          id: 1,
          title: "Дурак",
          comission: 0,
        },
        {
          id: 2,
          title: "Слоты",
          comission: 0,
        },
        {
          id: 3,
          title: "Зарики",
          comission: 0,
        },
      ],
    };
  },
};
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.xmx-datepicker,
.w-full {
  width: 100%;
}
</style>
